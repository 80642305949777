.site-header > .row,
.site-navigation .menu {
  --gap: 0 !important;
}

@media screen and (max-width: 719px) {
  .js .site-navigation {
    justify-content: center;
  }
  .site-branding{
    margin-bottom: 0;
  }
}

@media screen and (min-width: 900px) {
  .site-branding{
    margin-bottom: .5rem;
  }

  .site-navigation a {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1021px) {
  .site-navigation a {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1180px) {
  .site-navigation a {
    font-size: 1.2rem;
  }
}

.sidemenu-nav{
  height: 60px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.links-container{
  height: 100%; 
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sidemenu-nav a{
  font-size:large;
  height: 100%;
  padding: 0px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;
}

.sidemenu-nav a:hover{
  background-color: var(--color-primary);
  color: white;
}

#sidemenu-active{
  display:none;
}

.open-sidemenu-button, .close-sidemenu-button, 
.sidemenu-searchbar, .site-actions-mobile, .sidemenu-top-row {
  display: none;
}


@media(max-width:1400px){

  .sidemenu-searchbar, .site-actions-mobile, .sidemenu-top-row{
    display: block;
  }

  .site-search-toggle{
    display:none !important;
  }

  .sidemenu-top-row{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center; 
  }

  .site-actions-mobile{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .site-actions-mobile a{
    border: solid 2px white;
  }

  .sidemenu-nav{
    height:auto;
  }

  .site-actions-desktop{
    display: none;
  }
  .links-container{
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;

    position:fixed;
    top: 0; 
    right: -100%;
    z-index:99999;
    width:min(400px, 90%);

    background-color: var(--color-primary);
    color: white;
    box-shadow: -10px 0 10px rgba(0,0,0,.3);
    border-left: rgba(237, 237, 237, 0.6) solid 1px; 

    transition: .3s ease-out;

    /* Füge folgende Regeln hinzu */
    max-height: 100vh; /* Maximale Höhe: die volle Höhe des Viewports */
    overflow-y: auto; /* Vertikales Scrollen, wenn der Inhalt die Höhe überschreitet */
  }

  .sidemenu-nav a{
    box-sizing: border-box;
    height: auto;
    width: 100%;
    justify-content: flex-start;
  }

  .open-sidemenu-button, .close-sidemenu-button{
    display: block;
    cursor:pointer;
    z-index: 999999;
    transition: transform 0.2s ease-in-out; /* transition-transform */
  }

  .open-sidemenu-button:hover, .close-sidemenu-button:hover{
    color:var(--color-accent);
    cursor: pointer;
  }

  .open-sidemenu-button:active, .close-sidemenu-button:active{
    transform: scale(0.8);

  }

  #sidemenu-active:checked ~ .links-container{
    right: 0;
  }

  #sidemenu-active:checked ~ #sidemenu-overlay{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0; 
    left: 0;
    z-index:99998;
  }

  .sidemenu-searchbar .search-input{
    border: 2px solid var(--color-background-main);
    color: var(--color-text-main);
  }

  .sidemenu-searchbar .search-button{
    border: 2px solid var(--color-background-main);
    background-color: var(--color-background-main);
    color: var(--color-primary);
  }


  .sidemenu-searchbar .search-button:hover{
    background: var(--color-primary);
    color: var(--color-background-main);
  }

}

/* Styles für verkleinerte Sticky NavBar */

.show-when-scrolled{
  display: none;
}
