#site-actions {
  --gap: 0.5rem;
}

#site-actions a,
#site-actions button {
  background-color: var(--color-primary);
  border-radius: 50%;
  color: var(--color-text-primary);
  padding: 0.4rem 0.49rem; /* this padding makes the icons perfectly square */
}

#site-actions .badge--counter {
  background-color: var(--color-accent);
  color: var(--color-primary);
  font-weight: 700;
}
