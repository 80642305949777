/* TODO the text "zu den Details" should be made translatable and accessible by including it in the HTML */

.participants.grid img,
.products.grid img {
  transition: transform 0.3s ease-in-out;
}

.participants.grid a .frame:after,
.products.grid a .frame:after {
  align-items: center;
  background: var(--color-primary--transparency);
  bottom: 0;
  color: var(--color-text-primary);
  content: 'zu den Details';
  display: flex;
  font-family: var(--font-family-main);
  font-size: 1.2rem;
  height: 20%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  transform: scaleY(0);
  transform-origin: bottom;
  width: 100%;
}

.participants.grid a .frame,
.products.grid a .frame {
  position: relative;
}

.participants.grid a:hover img,
.products.grid a:hover img {
  transform: scale(1.03);
}

.participants.grid a:hover .frame:after,
.products.grid a:hover .frame:after {
  transform: scaleY(1);
  transition: transform 0.3s;
}
