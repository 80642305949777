.price-badge {
  background: var(--color-primary);
  border-radius: 2rem;
  color: var(--color-white);
  margin: 0 !important;
  padding: 0.3em;
  padding: 0.2rem 1rem;
  position: absolute;
  right: 1rem;
  /* transform: rotate(45deg) translateX(50%); */
  /* transform-origin: center center; */
  text-align: center;
  top: 1rem;
  z-index: var(--z-default);
}

.product-block .price-badge {
  font-size: 0.9rem;
}
