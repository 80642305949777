.facebook-posts-block .news-actions a {
  align-items: center;
  color: var(--color-text-muted);
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-header);
  font-size: 1.3rem;
  font-weight: bold;
  opacity: 0.5;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.facebook-posts-block .news-actions a:hover,
.facebook-posts-block .news-actions a:focus {
  color: var(--color-primary);
  opacity: 1;
}

.facebook-posts-block .news-actions a .icon {
  height: 2rem;
  width: 2rem;
}
