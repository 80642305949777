.main-menu{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
}

.submenu-toggle{
    display: none;
}

.submenu li{
    list-style-type: none;
}

.submenu > li > a {
    width: 100%;
}

/* Stil für das Parent-Menu-Item */
.has-submenu {
    position: relative; /* Setzt das Parent-Menu-Item als Bezugspunkt für die absolut positionierten Child-Elemente */
}

/* Stil für das Submenu */
.submenu {
    display: none; /* Versteckt das Submenu standardmäßig */
    position: absolute; /* Positioniert das Submenu absolut relativ zum Parent-Menu-Item */
    left: 0; /* Positioniert das Submenu am linken Rand des Parent-Menu-Items */
    top: 100%; /* Positioniert das Submenu direkt unter dem Parent-Menu-Item */
    z-index: 99999; /* Stellt sicher, dass das Submenu über anderen Elementen angezeigt wird */
    background-color: #fff; /* Hintergrundfarbe des Submenus */
    box-shadow: 0 8px 16px rgba(0,0,0,0.1); /* Schatten für das Submenu */
    border-bottom: 1px solid #ddd; /* Rahmen für das Submenu */
    border-left: 1px solid #ddd; /* Rahmen für das Submenu */
    border-right: 1px solid #ddd; /* Rahmen für das Submenu */
    min-width: 200px; /* Minimale Breite des Submenus */
    padding: 1rem;
    white-space: nowrap; /* Verhindert den Umbruch innerhalb des Submenus */
    max-width: 100vw; /* Stellt sicher, dass das Submenu nicht breiter als der Viewport ist */
    overflow-x: auto; /* Ermöglicht horizontales Scrollen, falls das Submenu breiter als der Viewport ist */
}

/* Stil für das Anzeigen des Submenus beim Hover */
.has-submenu:hover .submenu {
    display: block; /* Zeigt das Submenu beim Hover über das Parent-Menu-Item an */
}

.open-dropdown-menu, .close-dropdown-menu{
    display: none;
    background-color: none;
}

.mobile-menu{
    display: none;
    
}
.menu-toggle{
    display: none;
    pointer-events:none;
    visibility: hidden;
    opacity: 0;

}
    

.mobile-menu-hidden{
    display: none;
}

@media(max-width:1400px){
    .main-menu{
        flex-direction: column !important;
        padding: 20px 0px;
    }

    .main-menu a{
        color: white;
        border-bottom: solid 1px white;
    }

    .main-menu a:hover{
        color: var(--color-accent);
    }

    .has-submenu:hover .submenu {
        display: none;
    }

    .open-dropdown-menu, .close-dropdown-menu{
        display: block;
        z-index:99999;
        background-color: var(--color-background-main);
    }

    .open-dropdown-menu:hover, .close-dropdown-menu:hover{
        background-color: var(--color-accent);
    }


    .dropdown-arrow-menu{
        display: none !important;
    }

    .close-dropdown-menu{
        display: none;
    }

    /* Standardmäßig das mobile Menü ausblenden */
    .mobile-menu {
        display: none;
    }  

}