.site-main {
  letter-spacing: 0.02em;
  line-height: 1.75;
}

.site-main h2 {
  font-size: 2.2rem;
}

.site-main h3 {
  font-size: 1.7rem;
}

/* Change the focus outline to dotted */
:focus,
a:focus {
  outline: 3px dotted var(--color-link, #000);
  outline-offset: 3px;
}

/* Underline significant headers */
/* h2:not([class]),
h2[class*='margin-'],
h2[class*='text-align-'] {
  margin-block-end: calc(1rem + 2px);
  padding-block-end: calc(1rem - 2px);
  position: relative;
}

h2:not([class]) + .stack,
h2[class*='margin-'] + .stack,
h2[class*='text-align-'] + .stack {
  margin-block-start: 0;
}

h2:not([class]):after,
h2[class*='margin-']:after,
h2[class*='text-align-']:after {
  background: var(--color-primary);
  content: '';
  height: 4px;
  left: 0;
  position: absolute;
  top: 100%;
  width: 7rem;
}

h2.text-align-center:after {
  left: 50%;
  transform: translateX(-50%);
}

h2.text-align-end:after {
  left: unset;
  right: 0;
}

aside > h2,
aside > h3 {
  color: var(--color-primary);
}

aside > h2:not([class]):after,
aside > h2[class*='margin-']:after,
aside > h2[class*='text-align-']:after {
  width: 100%;
} */

/*
Highlighted links currently used for navigation links in header 
& filter links in sidebar.
*/
.category-filters a,
.site-navigation a {
  color: var(--color-text-main);
  position: relative;
}

.category-filters a:hover,
.site-navigation a:hover {
  background: transparent;
  color: var(--color-text-primary);
  outline: none;
  text-decoration: none;
  transition: 0.15s;
}

.category-filters a:before,
.site-navigation a:before {
  background: var(--color-primary);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scaleX(0);
  transform-origin: right;
  width: 100%;
}

.category-filters a:hover:before,
.site-navigation a:hover:before {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.25s;
}

.category-filters a span,
.site-navigation a span {
  color: inherit;
  position: relative;
  z-index: var(--z0);
}
