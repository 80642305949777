.tile-menu {
  padding-block: 1.5rem;
}

.tile {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  margin-block-end: 2rem;
  min-height: 230px;
  padding: 1rem;
  position: relative;
}

.tile .tile-content {
  background: var(--color-grey);
  padding: 1rem;
}

.tile .tile-content h3 {
  hyphens: unset;
  margin: 0;
}

.tile:not(.has-no-text) h3 {
  padding-block: 2rem 1rem;
  position: relative;
}

.tile:not(.has-no-text) h3:before {
  background: var(--color-primary);
  content: '';
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 7rem;
}

.tile .tile-content h3 a {
  color: var(--color-text-main);
  display: block;
  text-decoration: none;
}

.tile > a {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.tile.has-no-text {
  margin-block-end: 3rem;
}

.tile.has-no-text .tile-content {
  bottom: 0;
  left: 50%;
  padding: 0;
  position: absolute;
  text-align: center;
  transform: translate(-50%, 1rem);
}

.tile.has-no-text .tile-content h3 a,
.tile.has-no-text .tile-content.tile-no-link h3 {
  padding: 0.5rem 0.8rem;
}

@media screen and (min-width: 900px) {
  .tile-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -0.5rem;
  }

  .tile,
  .tile.has-no-text {
    flex: 1 0 30%;
    margin: 0.5rem 0.5rem 1.5rem;
  }
}
