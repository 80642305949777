/*
Category filters (Sidebar)
 */

.filter-reset-button:hover {
    color: var(--color-primary);
}

.filter-reset-button {
    color: var(--color-primary);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.filter-reset-button:active {
    transform: scale(0.85);
}

 .selected-category {
    background-color: var(--color-primary);
    padding: 0.5rem;
    color: white; /* Optional, falls die Textfarbe angepasst werden muss */
}

/* 
Underline significant headers
 */

.category-underline{
    margin-block-end: calc(1rem + 2px);
    padding-block-end: calc(1rem - 2px);
    position: relative;
}

.category-underline:after{
    background: var(--color-primary);
    content: '';
    height: 4px;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
}

