@keyframes fade-in {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.7;
  }
}

/* 
Credits: https://unused-css.com/blog/css-shake-animation/
*/
@keyframes rise-shake {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0);
  }
  55% {
    transform: translateY(-9px);
  }
  60% {
    transform: translateY(-9px) rotate(7deg);
  }
  65% {
    transform: translateY(-9px) rotate(-7deg);
  }
  70% {
    transform: translateY(-9px) rotate(7deg);
  }
  75% {
    transform: translateY(-9px) rotate(-7deg);
  }
  80% {
    transform: translateY(0) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
