.home .banner {
  flex-wrap: wrap;
  position: relative;
}

.home .banner .banner-icon {
  display: block;
  flex: 1 0 100%;
  max-width: 150px;
}

.banner .banner-icons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: space-around;
  margin-top: 2rem;
  position: relative;
  width: 100%;
}

.banner .banner-icon {
  display: flex;
  flex-direction: column;
}

.banner .banner-icon.event-icon .event-image {
  margin-block-end: 0.5rem;
  transform: translateY(-6px) rotate(-5deg);
}

.banner .banner-icon.event-icon .event-text {
  background: var(--color-primary);
  border-radius: 50%;
  color: var(--color-text-main--dark);
  display: inline-block;
  margin-block-start: -1.5rem;
  padding: 1rem;
  text-align: center;
}

@media screen and (min-width: 600px) {
  .home .banner .banner-icon {
    bottom: 2rem; /* Margin-top of .banner-icons parent element */
    position: absolute;
    right: 0;
    transform: translateY(50%);
  }

  .home .banner .banner-icon.event-icon {
    left: 0;
    right: unset;
  }
}

@media screen and (min-width: 900px) {
  .home .banner .banner-icon,
  .home .banner .banner-icon svg {
    max-width: 200px;
  }
}

@media screen and (min-width: 1200px) {
  .home .banner .banner-icon {
    max-width: 250px;
  }
}

@media screen and (min-width: 1700px) {
  .home .banner .banner-icon {
    transform: translate(50%, 50%);
  }
  .home .banner .banner-icon.event-icon {
    transform: translate(0, 50%);
  }
}
