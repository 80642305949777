button,
.button {
  text-transform: uppercase;
}

.button:focus,
.button:hover {
  border-color: var(--color-primary);
}

.button--outline:focus,
.button--outline:hover {
  border-color: var(--color-background-button-outline--interact);
}
