.dmp-landing .banner {
  overflow: hidden;
}
.dmp-landing .banner-blurry-image {
  display: none;
}

.dmp-landing .banner-full-bleed .container {
  max-width: none;
  padding-inline: 0;
}

.dmp-landing .banner-full-bleed .banner {
  display: block;
}

.dmp-landing .banner-full-bleed .banner-image img {
  max-width: none;
  object-fit: contain;
  width: 100%;
}

@media screen and (min-width: 840px) {
  .dmp-landing .banner-full-bleed .banner {
    height: 900px !important;
    /* height: calc(100vh - 83.7px); */
    position: relative;
  }

  .dmp-landing .banner-full-bleed .banner-image {
    left: 50%;
    position: absolute;
    top: 0%;
    transform: translateX(-50%);
  }

  .dmp-landing .banner-full-bleed .banner-image,
  .dmp-landing .banner-full-bleed .banner-image img {
    height: 100%;
    max-width: none;
  }
  .dmp-landing .banner-full-bleed .banner-image img {
    width: auto;
  }
}
