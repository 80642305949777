.image-and-text-block {
  --gutter: 1rem;
}

.image-and-text-block > :first-child {
  position: relative;
  z-index: var(--z0);
}

.image-and-text-block > :first-child:before {
  block-size: 100%;
  content: '';
  padding: 0 5rem;
  position: absolute;
  z-index: var(--z-1);
}

.image-and-text-block:not(.flex-row-reverse) > :first-child:before {
  background: transparent
    linear-gradient(
      270deg,
      #fff 50%,
      hsla(0, 0%, 100%, 0.8) 60%,
      hsla(0, 0%, 100%, 0.6) 70%,
      hsla(0, 0%, 100%, 0.4) 80%,
      hsla(0, 0%, 100%, 0.2) 90%,
      transparent
    )
    repeat scroll 0 0;
  right: -1rem;
  transform: translateX(50%);
}

.image-and-text-block.flex-row-reverse > :first-child:before {
  background: transparent
    linear-gradient(
      270deg,
      #fff 50%,
      hsla(0, 0%, 100%, 0.8) 60%,
      hsla(0, 0%, 100%, 0.6) 70%,
      hsla(0, 0%, 100%, 0.4) 80%,
      hsla(0, 0%, 100%, 0.2) 90%,
      transparent
    )
    repeat scroll 0 0;
  left: -1rem;
  transform: translateX(-50%);
}
