.interview-block + .interview-block {
  margin-block-start: 14rem;
}

.interview-block:last-of-type {
  margin-block-end: 10rem;
}

.interview-block .container {
  position: relative;
}

/* 
The :has selector does not work in Firefox (14.04.2023), but 
the only consequence is a missing dashed border between interviews.
*/
section:has(.interview-block)
  + section:has(.interview-block)
  .container:before {
  border-top: 1px dashed var(--color-text-main);
  content: '';
  height: 1px;
  left: 1.5rem;
  position: absolute;
  top: -7.5rem;
  width: calc(100% - 2 * 4vw);
}

.interview-block .interview-block__image {
  margin-inline: calc(4vw * -1);
  width: calc(100% + 2 * 4vw);
}

.interview-block .interview-block__image img {
  width: 100%;
}

.interview-block .interview-block__title {
  margin-block-start: 2.5rem;
}

@media screen and (min-width: 840px) {
  .interview-block + .interview-block {
    margin-block-start: 10rem;
  }

  .interview-block + .interview-block .container:before {
    top: -6rem;
  }

  .interview-block .container .interview-block__image {
    margin: 0 auto 0.2rem;
  }

  @supports (grid-area: auto) {
    .interview-block .container {
      display: grid;
      gap: 2rem;
      grid-template-areas:
        'image title'
        'content content';
      grid-template-columns: repeat(2, 1fr);
    }
    .interview-block.interview-block--reverse .container {
      grid-template-areas:
        'title image'
        'content content';
    }
    .interview-block .container .interview-block__image {
      grid-area: image;
      width: 100%;
    }
    .interview-block .container .interview-block__title {
      align-self: end;
      grid-area: title;
      margin-block-end: 0;
    }
    .interview-block .container .interview-block__content {
      grid-area: content;
    }
  }
}

@media screen and (min-width: 1480px) {
  .interview-block + .interview-block {
    margin-block-start: 6rem;
  }

  .interview-block + .interview-block .container:before {
    top: -3.5rem;
  }
  @supports (grid-area: auto) {
    .interview-block .container {
      grid-template-areas:
        'image title'
        'image content';
      grid-template-columns: 2fr 3fr;
    }
    .interview-block.interview-block--reverse .container {
      grid-template-areas:
        'title image'
        'content image';
      grid-template-columns: 3fr 2fr;
    }

    .interview-block .container .interview-block__image {
      margin: 2.9rem auto 0;
    }
    .interview-block .container .interview-block__title {
      align-self: auto;
    }
    .interview-block .container .interview-block__content p {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 839px) {
  .interview-block .interview-block__image figcaption {
    margin-inline: auto;
    max-width: var(--max-inline-size);
    padding-inline: 4vw;
    width: 100%;
  }
}
