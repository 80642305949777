/* 
The slugs of these pages should not be edited or else 
the styles will not work anymore...but slugs of 
public pages shouldn't be changed anyway, so it should 
be fine.
*/

.liebe-ist-mit-allen-sinnen-zu-leben,
.liebe-ist-kampagne-interviews {
  --font-family-header: 'Veneer Three', system-ui, sans-serif;
  --measure: 80ch;
}

.liebe-ist-mit-allen-sinnen-zu-leben h2,
.liebe-ist-mit-allen-sinnen-zu-leben h3,
.liebe-ist-mit-allen-sinnen-zu-leben h4,
.liebe-ist-kampagne-interviews h2,
.liebe-ist-kampagne-interviews h3,
.liebe-ist-kampagne-interviews h4 {
  color: var(--color-primary);
}

.liebe-ist-mit-allen-sinnen-zu-leben .gallery-slider__text {
  font-family: 'Source Serif Pro', system-ui, sans-serif;
}
