:root {
  --line-height: 1.1;

  /* Color Overrides of dmp_pages */
  --color-primary: rgb(4, 75, 54);
  --color-primary--transparency: rgba(4, 75, 54, 0.9);
  --color-secondary: rgb(63, 53, 53);
  --color-accent: rgb(255, 228, 63);
  --color-background-footer: var(--color-primary);
  --color-background-main: rgb(255, 255, 255);
  --color-link: rgb(47, 120, 81);
  --color-link--interact: var(--color-primary);
  --color-text-footer: rgb(255, 255, 255);
  --color-text-main: rgb(63, 53, 53);
  /* --font-family-header: 'Kanit', system-ui, sans-serif;
  --font-family-main: 'Source Sans Pro', system-ui, sans-serif; */
  --font-weight-header: 600;
  --text-transform-menu: uppercase;
  --color-background-button--hover: rgb(4, 46, 33);
  --color-background-button-primary: var(--color-primary);
  --color-text-button-primary: var(--color-text-primary);
  --color-background-button-primary--interact: var(--color-background-main);
  --color-text-button-primary--interact: var(--color-primary);
  --color-background-button-secondary: var(--color-secondary);
  --color-text-button-secondary: var(--color-text-secondary);
  --color-background-button-secondary--interact: var(--color-background-main);
  --color-text-button-secondary--interact: var(--color-primary);
  --color-text-button-outline: var(--color-primary);
  --color-background-button-outline--interact: var(--color-primary);
  --color-text-button-outline--interact: var(--color-text-primary);

  /* Custom Theme Colors */
  --color-grey: rgb(225, 225, 225);
  --color-dark-grey: rgb(132, 132, 132);
  --color-grey-opacity: rgba(225, 225, 225, 0.9);
}
