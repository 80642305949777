/* Additional styles for gallery sliders */

.gallery-slider .splide__slide {
  display: flex;
  flex-direction: column;
}

.gallery-slider__text {
  background: var(--color-primary);
  color: var(--color-text-primary);
  flex: 1 0 auto;
  font-size: 120%;
  padding: 4rem 1.5rem;
}

.gallery-slider__read-more {
  color: #ffe146;
  display: block;
  font-size: 1.3rem;
  font-weight: 600;
  margin-block-start: 1rem;
  text-decoration: none;
  text-transform: lowercase;
}
.gallery-slider__read-more svg {
  margin-block-end: 0.2rem;
  vertical-align: middle;
}
