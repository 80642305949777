.title-text-with-info-box-block {
  /* 
  Remove the spacing above the block. The amount of spacing is dependent on
  the `stack` class used for the .site-main element in the _base.html.
  */
  margin-block-start: calc(var(--space, 1.5rem) * -2);
}

.title-text-with-info-box-block,
.title-text-with-info-box-block .container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.title-text-with-info-box-block {
  padding-block: 2rem;
}

.title-text-with-info-box-block h2 {
  margin-block: 2rem 0.5rem;
}

.title-text-block__info-box {
  align-self: flex-end;
  margin-block-start: -5rem;
}

section + section .title-text-block__info-box {
  margin-block-start: -10rem;
}

.info-box {
  background: rgb(226, 236, 228);
  color: var(--color-text-main);
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.2;
  max-width: 100%;
}

.info-box-header {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: space-around;
  max-width: 100%;
  padding: 1rem 2rem;
}

.info-box-header > * {
  flex: 0 1 30%;
  max-width: 30%;
}

.info-box-header img {
  flex: 0 1 100px;
}

.info-box-text {
  padding: 0 1.5rem 1.5rem;
}

@media screen and (min-width: 840px) {
  .info-box {
    background: rgba(226, 236, 228, 0.8);
    font-size: 0.8rem;
    margin-inline-end: 0;
    max-width: 50%;
  }
  .info-box-header {
    font-size: 90%;
  }
}

@media screen and (min-width: 1020px) {
  .info-box {
    font-size: 0.9rem;
    max-width: 35%;
  }
}
