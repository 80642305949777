/* Headings, Menu: Roboto */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Roboto-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Roboto-Black.ttf') format('truetype');
}

/* Text, Body: Source Sans Pro */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/SourceSansPro-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/SourceSansPro-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/BebasNeue-Regular.ttf') format('truetype');
}



/* Alte Fonts */

/* source-serif-pro-300 - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('../fonts/source-serif-pro-v15-latin-300.woff2') format('woff2'),
    url('../fonts/source-serif-pro-v15-latin-300.woff') format('woff'),
    url('../fonts/source-serif-pro-v15-latin-300.ttf') format('truetype');
}

/* source-serif-pro-regular - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../fonts/source-serif-pro-v15-latin-regular.woff2') format('woff2'),
    url('../fonts/source-serif-pro-v15-latin-regular.woff') format('woff'),
    url('../fonts/source-serif-pro-v15-latin-regular.ttf') format('truetype');
}

/* Veneer */
@font-face {
  font-family: 'Veneer Three';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../fonts/VeneerThree.woff2') format('woff2'),
    url('../fonts/VeneerThree.woff') format('woff'),
    url('../fonts/VeneerThree.ttf') format('truetype');
}
